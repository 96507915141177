@import url(./fonts.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

.hover-menu {
	opacity: 0;
	transition: 0.5s opacity;
}

div:hover > .hover-menu, .hover-menu.open {
	opacity: 1;
	z-index: 2;
}

svg.icon {
	display: inline-block;
}

.swiper-button-prev, .swiper-button-next {
	width: 48px;
	height: 48px;
	border-radius: 100%;
	transition: background-color 0.4s, color 0.4s;
}

.swiper-button-prev::after {
	font-size: 30px;
	margin-left: -3px;
}

.swiper-button-next::after {
	font-size: 30px;
	margin-right: -3px;
}

.scroll-effect .scroll-box {
	transform: translate(0, 200px);
	opacity: 0;
	transition-property: opacity, transform;
}

.scroll-effect.scroll-visible .scroll-box {
	transition-duration: 1s;
	transform: translate(0, 0);
	opacity: 1;
}

.scroll-effect.scroll-visible .scroll-box:nth-child(2) {
	transition-delay: 0.5s;
}

.scroll-effect.scroll-visible .scroll-box:nth-child(3) {
	transition-delay: 1s;
}

.collapsible-container > div {
	max-height: 0px;
	overflow: hidden;
	transition: 3s max-height;
}

.collapsible-container > div.open {
	max-height: 800px;
}

.collapsible-container > button svg {
	transform: rotate(180deg);
}

.collapsible-container > button.open svg {
	transform: rotate(0);
}

.prose {
	font-size: 1.125rem;

	a {
		color: #1069A6;
		text-decoration: none;
	}
}

.donate-form input[type=text], .donate-form input[type=email] {
	padding: 4px;
	border: 1px solid #CCC;
	margin: 5px;
}

.donate-form label {
	cursor: pointer;
	margin: 5px;
}

.blog-body .mx-auto {
  margin: 0;
}

body {
  background: #000 !important;
}

html .offside--right {
  width: 300px;
  right: -300px;

  &.is-open {
    transform: translate3d(-300px, 0, 0);
  }
}

.body-container {
  @apply bg-lightgray;
  transition: opacity 0.3s cubic-bezier(.16, .68, .43, .99);
}

html .offside-js--is-right .offside-sliding-element {
  transform: translate3d(0, 0, 0);
  opacity: 0.3;
}
