@font-face {
  font-weight: 400;
  font-family: 'Slabo 27px';
  font-style: normal;
  src: url('./fonts/Slabo 27px.woff2') format('woff2'),
    url('./fonts/Slabo 27px.ttf') format('ttf');
}

@font-face {
  font-weight: 400;
  font-family: 'Source Sans 3';
  font-style: normal;
  src: url('./fonts/SourceSans3-Regular.ttf.woff2') format('woff2'),
    url('./fonts/SourceSans3-Regular.ttf') format('ttf');
}

@font-face {
  font-weight: 400;
  font-family: 'Source Sans 3';
  font-style: italic;
  src: url('./fonts/SourceSans3-It.ttf.woff2') format('woff2'),
    url('./fonts/SourceSans3-It.ttf') format('ttf');
}

@font-face {
  font-weight: 700;
  font-family: 'Source Sans 3';
  font-style: normal;
  src: url('./fonts/SourceSans3-Bold.ttf.woff2') format('woff2'),
    url('./fonts/SourceSans3-Bold.ttf') format('ttf');
}

@font-face {
  font-weight: 700;
  font-family: 'Source Sans 3';
  font-style: italic;
  src: url('./fonts/SourceSans3-BoldIt.ttf.woff2') format('woff2'),
    url('./fonts/SourceSans3-BoldIt.ttf') format('ttf');
}
